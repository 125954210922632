import { FunctionComponent } from 'react';

import { Attendee } from '../../types/Activity';

import Entry from './Entry';

interface Props {
    entryOneError?: string;
    entryOnePlayerIds: number[];
    entryTwoError?: string;
    entryTwoPlayerIds: number[];
    onEntryOnePlayersSelect(playerIds: number[]): void;
    onEntryTwoPlayersSelect(playerIds: number[]): void;
    players: Attendee[];
}

const Entries: FunctionComponent<Props> = ({
    entryOneError,
    entryOnePlayerIds,
    entryTwoError,
    entryTwoPlayerIds,
    onEntryOnePlayersSelect,
    onEntryTwoPlayersSelect,
    players,
}) => (
    <div className="gap-x-6 grid grid-cols-7">
        <Entry
            error={ entryOneError }
            onSelect={ onEntryOnePlayersSelect }
            players={ players }
            selectedPlayerIds={ entryOnePlayerIds }
            unavailablePlayerIds={ entryTwoPlayerIds }
        />

        <div className="col-span-1 grid grid-rows-3 row-span-2">
            <div className="flex h-full justify-center row-span-1 w-full">
                <div className="bg-gray-800 w-[0.2rem]" />
            </div>

            <div className="flex items-center justify-center row-span-1">
                <div className="bg-gray-800 flex font-semibold h-4 items-center justify-center pb-0.5 rounded-full text-xs text-gray-500 w-7">
                    vs
                </div>
            </div>

            <div className="flex h-full justify-center row-span-1 w-full">
                <div className="bg-gray-800 w-[0.2rem]" />
            </div>
        </div>

        <Entry
            error={ entryTwoError }
            onSelect={ onEntryTwoPlayersSelect }
            players={ players }
            selectedPlayerIds={ entryTwoPlayerIds }
            unavailablePlayerIds={ entryOnePlayerIds }
        />
    </div>
);

export default Entries;
