import { PlusIcon } from '@heroicons/react/20/solid';
import { AnimatePresence } from 'framer-motion';
import { FunctionComponent, useState } from 'react';

import { Attendee } from '../../types/Activity';

import PlayerSelector from './PlayerSelector';

interface Props {
    error?: string;
    onSelect(playerIds: number[]): void;
    players: Attendee[];
    selectedPlayerIds: number[];
    unavailablePlayerIds: number[];
}

const Entry: FunctionComponent<Props> = ({
    error,
    onSelect,
    players,
    selectedPlayerIds,
    unavailablePlayerIds,
}) => {
    const [isPlayerSelectorOpen, setIsPlayerSelectorOpen] = useState<boolean>(false);

    return (
        <>
            <div className="col-span-3 py-3 space-y-6">
                { selectedPlayerIds.map(selectedPlayerId => {
                    const player = players.find(({ user: { id } }) => id === selectedPlayerId);

                    return player ? (
                        <button
                            className="bg-gray-800 bg-opacity-50 border border-transparent inline-flex items-center px-2 py-1 rounded-md w-full focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
                            key={ player.id }
                            onClick={ () => setIsPlayerSelectorOpen(true) }
                            type="button"
                        >
                            <div className="flex items-center space-x-3 w-full">
                                <img
                                    alt={ `${player.user.firstName} ${player.user.lastName}'s avatar` }
                                    className="block h-8 rounded-full w-8"
                                    src={ player.user.avatarUrl }
                                />

                                <div className="leading-none line-clamp-2 text-gray-300 text-left text-xs">
                                    { `${player.user.firstName} ${player.user.lastName}` }
                                </div>
                            </div>
                        </button>
                    ) : null
                }) }

                { selectedPlayerIds.length < 2 ? (
                    <button
                        className="bg-gray-800 bg-opacity-50 border border-transparent inline-flex items-center justify-center px-2 py-1.5 rounded-md focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
                        onClick={ () => setIsPlayerSelectorOpen(true) }
                        type="button"
                    >
                        <div className="flex items-center pr-1 space-x-1.5">
                            <PlusIcon
                                aria-hidden="true"
                                className="h-4 text-orange-700 w-4"
                            />

                            <span className="text-orange-500 text-xs">
                                Add player
                            </span>
                        </div>
                    </button>
                ) :  null }

                { error ? (
                    <p
                        className="mt-1 text-red-500 text-xs"
                        id="entry-error"
                        role="alert"
                    >
                        { error }
                    </p>
                ) : null }
            </div>

            <AnimatePresence>
                { isPlayerSelectorOpen ? (
                    <PlayerSelector
                        onClose={ () => setIsPlayerSelectorOpen(false) }
                        onSelect={ playerIds => {
                            onSelect(playerIds);

                            setIsPlayerSelectorOpen(false);
                        } }
                        players={ players }
                        selectedPlayerIds={ selectedPlayerIds }
                        unavailablePlayerIds={ unavailablePlayerIds }
                    />
                ): null }
            </AnimatePresence>
        </>
    );
};

export default Entry;
