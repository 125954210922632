import { FunctionComponent } from 'react';

import ActivityCardErrorStateIllustration from './ActivityCardErrorStateIllustration';

const ActivityCardErrorState: FunctionComponent = () => (
    <div className="bg-gray-800 bg-opacity-50 border border-gray-800 flex flex-col items-center justify-center px-4 py-8 rounded-lg space-y-6">
        <ActivityCardErrorStateIllustration />

        <div className="text-center">
            <span className="text-gray-400 text-sm">
                Whoops! Seems like the server is having some issue.
            </span>
            
            <br />

            <br />
            
            <span className="text-gray-400 text-sm">
                Please try again later.
            </span>
        </div>
    </div>
);

export default ActivityCardErrorState;
