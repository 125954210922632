import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
}

export const NavButton: FunctionComponent<Props> = ({
    children,
    ...props
}) => (
    <button
        className="bg-gray-800 border border-transparent inline-flex items-center justify-center p-0.5 rounded-md focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
        { ...props }
    >
        { children }
    </button>
);
