import { XMarkIcon } from '@heroicons/react/20/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { FunctionComponent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
// import { useHistory } from 'react-router';
import { array, number, object } from 'yup';

import Modal from '../../components/Modal';
import { Attendee } from '../../types/Activity';

import Entries from './Entries';
import Results from './Results';

const players: Attendee[] = [
    {
        id: 1,
        user: {
            avatarUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
            email: 'robin.ong@example.com',
            firstName: 'Robin',
            id: 1,
            lastName: 'Ong',        
        },
    },
    {
        id: 2,
        user: {
            avatarUrl: 'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
            email: 'nicole.lee@example.com',
            firstName: 'Nicole',
            id: 2,
            lastName: 'Lee',        
        },
    },
    {
        id: 3,
        user: {
            avatarUrl: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80',
            email: 'chongwei.lee@example.com',
            firstName: 'Chong Wei',
            id: 3,
            lastName: 'Lee',        
        },
    },
];

interface Props {
    onClose(): void;
}

interface FormData {
    entryOnePlayerIds: number[];
    entryOneScores: Array<number | string>;
    entryTwoPlayerIds: number[];
    entryTwoScores: Array<number | string>;
}

const playerIdSchema = number()
    .integer('Please enter valid player.')
    .moreThan(0, 'Please enter valid player');

const scoreSchema = number()
    .typeError('Please enter score.')
    .integer('Please enter an integer for score.')
    .min(0, 'Please enter a valid score');

const schema = object({
    entryOnePlayerIds: array()
        .required('Please add a player.')
        .min(1, 'Please add a player')
        .of(playerIdSchema),
    entryOneScores: array()
        .required('Please enter score.')
        .min(1, 'Please enter score')
        .of(scoreSchema),
    entryTwoPlayerIds: array()
        .required('Please add a player.')
        .min(1, 'Please add a player')
        .of(playerIdSchema),
    entryTwoScores: array()
        .required('Please enter score.')
        .min(1, 'Please enter score')
        .of(scoreSchema),
});

const AddMatch: FunctionComponent<Props> = ({
    onClose,
}) => {
    // const { push } = useHistory();
    
    const [entryOnePlayerIds, setEntryOnePlayerIds] = useState<number[]>([]);
    const [entryTwoPlayerIds, setEntryTwoPlayerIds] = useState<number[]>([]);
    const [entryOneScores, setEntryOneScores] = useState<Array<number | string>>(['']);
    const [entryTwoScores, setEntryTwoScores] = useState<Array<number | string>>(['']);

    const { handleSubmit, formState: { errors }, setValue } = useForm<FormData>({
        defaultValues: {
            entryOneScores: [''],
            entryTwoScores: [''],
        },
        resolver: yupResolver(schema),
    });

    console.log('errors', errors);

    const onSubmit: SubmitHandler<FormData> = data => {
        console.log('data', data);
        // const [startTimeHours, startTimeMinutes] = startTime.split(':');
        // const startedAtDateTime = new Date(startDate.toString()).setHours(Number(startTimeHours), Number(startTimeMinutes));

        // const [endTimeHours, endTimeMinutes] = endTime.split(':');
        // const endedAtDateTime = new Date(startDate.toString()).setHours(Number(endTimeHours), Number(endTimeMinutes));
        
        // if (isBefore(startedAtDateTime, endedAtDateTime)) {
        //     addDays(endedAtDateTime, 1);
        // }

        // mutate({
        //     endedAt: formatISO(endedAtDateTime),
        //     name,
        //     startedAt: formatISO(startedAtDateTime),
        //     // venue,
        // }, {
        //     onSuccess: ({ id }) => {
        //         push(`/activities/${id}`);

        //         onClose();
        //     }
        // });
    };

    return (
        <Modal onClose={ onClose }>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <div className="flex flex-col h-full p-6">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-1 items-center">
                            <button
                                className="bg-gray-800 border border-transparent font-semibold inline-flex items-center justify-center p-0.5 rounded-md focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
                                onClick={ onClose }
                                type="button"
                            >
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-5 text-orange-500 w-5"
                                />

                                <span className="sr-only">
                                    Close
                                </span>
                            </button>
                        </div>

                        <div className="flex flex-1 items-center justify-center">
                            <h2 className="font-bold leading-none text-gray-200 text-lg">
                                New match
                            </h2>
                        </div>

                        <div className="flex flex-1 items-center justify-end">
                            <button
                                className="bg-gray-800 border border-transparent inline-flex items-center leading-none justify-center px-2 py-1 rounded-md text-orange-500 focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>

                    <div className="flex-1 pt-6">
                        <div className="gap-6 grid grid-cols-6">
                            <div className="col-span-6">
                                <Entries
                                    entryOneError={ errors.entryOnePlayerIds?.message }
                                    entryOnePlayerIds={ entryOnePlayerIds }
                                    entryTwoError={ errors.entryTwoPlayerIds?.message }
                                    entryTwoPlayerIds={ entryTwoPlayerIds }
                                    onEntryOnePlayersSelect={ playerIds => {
                                        setEntryOnePlayerIds(playerIds);
                                        setValue('entryOnePlayerIds', playerIds);
                                    } }
                                    onEntryTwoPlayersSelect={ playerIds => {
                                        setEntryTwoPlayerIds(playerIds);
                                        setValue('entryTwoPlayerIds', playerIds);
                                    } }
                                    players={ players }
                                />
                            </div>

                            <div className="col-span-6">
                                <Results
                                    entryOneErrors={ errors.entryOneScores }
                                    entryOneScores={ entryOneScores }
                                    entryTwoErrors={ errors.entryTwoScores }
                                    entryTwoScores={ entryTwoScores }
                                    onEntryOneScoresChange= { scores => {
                                        setEntryOneScores(scores);
                                        setValue('entryOneScores', scores);
                                    } }
                                    onEntryTwoScoresChange= { scores => {
                                        setEntryTwoScores(scores);
                                        setValue('entryTwoScores', scores);
                                    } }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default AddMatch;
