import { Dialog } from '@headlessui/react';
import { motion } from 'framer-motion';
import { CSSProperties, FunctionComponent, MutableRefObject, ReactElement } from 'react';

interface Props {
    children: ReactElement;
    initialFocus?: MutableRefObject<HTMLElement | null>;
    onClose(): void;
}

const TRANSITION = {
    DURATION: 0.4,
    EASE: [0.32, 0.72, 0, 1],
}

const cache = new Map();

const set = (el: HTMLElement | null, styles: CSSProperties) => {
    if ( ! el) {
        return;
    }

    const originalStyles = {};

    Object.entries(styles).forEach(([key, value]) => {
        // @ts-ignore
        originalStyles[key] = el.style[key];
        // @ts-ignore
        el.style[key] = value;
    });

    cache.set(el, originalStyles);
}

const reset = (el: HTMLElement | null, prop?: keyof CSSProperties) => {
    if ( ! el) {
        return;
    }

    const originalStyles = cache.get(el);

    if (prop) {
        // @ts-ignore
        el.style[prop] = originalStyles[prop];

        return;
    }

    Object.entries(originalStyles).forEach(([key, value]) => {
        // @ts-ignore
        el.style[key] = value;
    });
}

const Modal: FunctionComponent<Props> = ({
    children,
    initialFocus,
    onClose,
}) => {
    return (
        <Dialog
            className="fixed inset-0 z-10"
            initialFocus={ initialFocus }
            onClose={ onClose }
            open={ true }
            static={ true }
        >
            <Dialog.Overlay
                animate="open"
                as={ motion.div }
                exit="close"
                className="bg-black/40 fixed inset-0"
                initial="close"
                onAnimationComplete={ (variant: string) => {
                    if (variant === 'close') {
                        reset(document.documentElement);

                        reset(document.body);

                        reset(document.querySelector('header'));

                        reset(document.querySelector('ion-app'));
                    }
                } }
                onAnimationStart={ (variant: string) => {
                    if (variant === 'open') {
                        set(document.documentElement, {
                            background: 'black',
                            height: '100vh',
                        });

                        set(document.body, {
                            inset: '0',
                            position: 'fixed',
                        });

                        set(document.querySelector('header'), {
                            position: 'absolute',
                        });

                        set(document.querySelector('ion-app'), {
                            borderRadius: '1.5rem',
                            overflow: 'hidden',
                            transform: 'scale(0.93) translateY(calc(env(safe-area-inset-top) + 8px))',
                            transitionDuration: `${TRANSITION.DURATION}s`,
                            transformOrigin: 'top',
                            transitionProperty: 'transform',
                            transitionTimingFunction: `cubic-bezier(${TRANSITION.EASE.join(',')})`,
                        });
                    }

                    if (variant === 'close') {
                        reset(document.querySelector('ion-app'), 'transform');
                    }
                } }
                variants={ {
                    close: {
                        opacity: 0,
                        transition: {
                            duration: TRANSITION.DURATION,
                            ease: TRANSITION.EASE,
                        },
                    },
                    open: {
                        opacity: 1,
                        transition: {
                            duration: TRANSITION.DURATION,
                            ease: TRANSITION.EASE,
                        },
                    },
                } }
            />

            <div className="container h-full max-w-xl mx-auto pointer-events-none">
                <motion.div
                    animate={ {
                        transition: {
                            duration: TRANSITION.DURATION,
                            ease: TRANSITION.EASE,
                        },
                        y: 0,
                    } }
                    className="bg-gray-900 h-full mt-4 pointer-events-auto rounded-t-3xl shadow-xl"
                    exit={ {
                        transition: {
                            duration: TRANSITION.DURATION,
                            ease: TRANSITION.EASE,
                        },
                        y: '100%',
                    } }
                    initial={ { y: '100%' } }
                >
                    { children }
                </motion.div>
            </div>
        </Dialog>
    );
};

export default Modal;
