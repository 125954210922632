import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { FunctionComponent } from 'react';

// Fix hover background color

interface Props {
    error?: string;
    onChange(value: number | string): void;
    title: string;
    value: number | string;
}

const ResultField: FunctionComponent<Props> = ({
    error,
    onChange,
    title,
    value,
}) => (
    <div className="font-medium group text-orange-600 text-sm focus-within:text-orange-500 hover:text-orange-500 hover:focus-within:text-orange-500">
        <div className="relative">
            { value > 0 ?  (
                <button
                    className="absolute flex inset-y-0 items-center left-0 pl-2"
                    onClick={ () => {
                        if (typeof(value) === 'number') {
                            onChange(value - 1);
                        }
                    } }
                    type="button"
                >
                    <MinusIcon
                        aria-hidden="true"
                        className="h-4 w-4"
                    />

                    <span className="sr-only">
                        Deduct score
                    </span>
                </button>
            ) : null }
            
            <label className="sr-only">
                { title }
            </label>

            <input
                aria-describedby={ error ? 'entry-error' : '' }
                aria-invalid={ !! error }
                className={ `bg-gray-800 bg-opacity-50 block border-0 font-normal px-8 rounded-md text-center text-gray-100 text-sm w-full focus:bg-opacity-100 focus:outline-none focus:ring-0` }
                min="0"
                onChange={ ({ currentTarget: { value } }) => {
                    if (value === '') {
                        onChange(value);
    
                        return;
                    }
    
                    onChange(parseInt(value));
                } }
                id="start-date"
                step="1"
                type="number"
                value={ value }
            />

            { value < 30 ? (
                <button
                    className="absolute flex inset-y-0 items-center right-0 pr-2"
                    onClick={ () => {
                        if (typeof(value) === 'string') {
                            onChange(1);
                        }

                        if (typeof(value) === 'number') {
                            onChange(value + 1);
                        }
                    } }
                    type="button"
                >
                    <PlusIcon
                        aria-hidden="true"
                        className="h-4 w-4"
                    />

                    <span className="sr-only">
                        Add score
                    </span>
                </button>
            ) : null }
        </div>

        { error ? (
            <p
                className="mt-1 text-red-500 text-xs"
                id="entry-error"
                role="alert"
            >
                { error }
            </p>
        ) : null }
    </div>
);

export default ResultField;
