import { useMutation, useQueryClient } from '@tanstack/react-query';

import http from '../../utils/http';
import { initializeCsrfProtection } from '../../utils/initializeCsrfProtection';

export interface RequestData {
    email: string;
    password: string;
}

const signIn = async (requestData: RequestData): Promise<void> => {
    await initializeCsrfProtection();

    await http.post<void>('/login', requestData);
};

export const useSignIn = () => {
    const queryClient = useQueryClient();

    return (useMutation({
        mutationFn: signIn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['currentUser'] });
        },
    }));
};
