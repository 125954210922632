import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Activity, ApiActivity, ApiVenue, Venue } from '../../types/Activity';
import http from '../../utils/http';

const mapToApiVenue = ({
    address1,
    address2,
    city,
    country,
    id,
    name,
    postalCode,
    state,
}: Venue): ApiVenue => ({
    address1,
    address2,
    city,
    country,
    id,
    name,
    postal_code: postalCode,
    state,
});

const mapToApiActivity = ({
    endedAt,
    name,
    startedAt,
    venue: apiVenue,
}: Omit<Activity, 'attendees' | 'id'>): Omit<ApiActivity, 'attendees' | 'id'> => ({
    ended_at: endedAt,
    name,
    started_at: startedAt,
    venue: apiVenue ? mapToApiVenue(apiVenue) : undefined,
});

const createActivity = async (activity: Omit<Activity, 'attendees' | 'id'>): Promise<Pick<Activity, 'id'>> => {
    const { data: { data } } = await http.post<{ data: Pick<Activity, 'id'>}>('/api/activities', mapToApiActivity(activity));

    return data;
};

const useCreateActivity = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createActivity,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['currentUserActivities'] });
        },
    });
};

export default useCreateActivity;
