import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import { ApiUser, User } from '../types/User';
import http, { HttpStatusCode } from '../utils/http';
import { initializeCsrfProtection } from '../utils/initializeCsrfProtection';

const mapToUser = ({
    email,
    first_name: firstName,
    id,
    last_name: lastName,
}: ApiUser): User => ({
    avatarUrl: `https://source.boringavatars.com/beam/120/${firstName}%20${lastName}?colors=EF4444,EAB308,22C55E,3B82F6,A855F7`,
    email,
    firstName,
    id,
    lastName,
});

const fetchCurrentUser = async (): Promise<ApiUser> => {
    await initializeCsrfProtection();

    const { data: { data }  } = await http.get<{ data: ApiUser }>('/api/current-user');

    return data;
};

export const useCurrentUser = () => useQuery({
    queryKey: ['currentUser'],
    queryFn: fetchCurrentUser,
    retry: (failureCount, error) => {
        if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
            return false;
        }

        return failureCount !== 3;
    },
    select: data => mapToUser(data),
});
