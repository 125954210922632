import { Tab } from '@headlessui/react';
import { ChevronRightIcon, ClockIcon, MapPinIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import { format, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { Fragment, FunctionComponent } from 'react';

import { Activity } from '../../types/Activity';

import BadmintonIcon from './BadmintonIcon';

interface Props {
    activity: Activity;
}

const DetailsTab: FunctionComponent<Props> = ({
    activity,
}) => (
    <Tab.Panel as={ Fragment }>
        <div className="pt-16">
            <div className="flex items-center space-x-3">
                <div className="bg-gray-800 bg-opacity-50 border border-transparent p-2 rounded-md">
                    <BadmintonIcon />
                </div>

                <div className="space-y-[10px]">
                    <div className="font-semibold leading-none text-gray-200 truncate">
                        { activity.name }
                    </div>
                </div>
            </div>

            <div className="pt-16 space-y-6">
                <div className="flex space-x-3">
                    <div className="flex-shrink-0 pt-0.5">
                        <ClockIcon
                            aria-hidden="true"
                            className="h-4 text-gray-700 w-4"
                        />
                    </div>

                    <div className="space-y-1">
                        <div className="flex items-center">
                            <span className="font-medium text-gray-400 text-sm">
                                { format(parseISO(activity.startedAt), 'd LLL yyyy (EEEE)') }
                            </span>
                        </div>

                        <div className="flex items-center">
                            <span className="font-medium text-gray-500 text-sm">
                                { format(parseISO(activity.startedAt), 'H:mm') }
                            </span>
                            
                            <div className="mx-1">
                                <ChevronRightIcon
                                    aria-hidden="true"
                                    className="h-3 text-gray-500 w-3"
                                />
                            </div>
                            
                            <span className="font-medium text-gray-500 text-sm">
                                { format(parseISO(activity.endedAt), 'H:mm') } ({ formatDuration(intervalToDuration({ start: parseISO(activity.startedAt), end: parseISO(activity.endedAt) })) }) 
                            </span>
                        </div>
                    </div>
                </div>

                { activity.venue ? (
                    <div className="flex space-x-3">
                        <div className="flex-shrink-0 pt-0.5">
                            <MapPinIcon
                                aria-hidden="true"
                                className="h-4 text-gray-700 w-4"
                            />
                        </div>

                        <div className="overflow-x-hidden space-y-1">
                            <div className="flex items-center">
                                <span className="font-medium text-gray-400 text-sm truncate">
                                    {/* { activity.venue } */}
                                </span>
                            </div>

                            <div className="flex items-center">
                                <span className="font-medium text-gray-500 text-sm truncate">
                                    {/* { `${activity.address}, ${activity.postalCode} ${activity.city}, ${activity.state}` } */}
                                </span>
                            </div>
                        </div>
                    </div>
                ) : null }

                <div className="flex space-x-3">
                    <div className="flex-shrink-0">
                        <UserGroupIcon
                            aria-hidden="true"
                            className="h-5 text-gray-700 w-5"
                        />
                    </div>

                    <div className="overflow-x-hidden space-y-2">
                        <div className="flex items-center">
                            <span className="font-medium text-gray-400 text-sm">
                                Participants ({ activity.attendees ? activity.attendees.length : 0 })
                            </span>
                        </div>

                        <div className="flex items-center space-x-3 overflow-auto">
                            { activity.attendees.map(({ id, user: { avatarUrl, firstName, lastName } }) => (
                                <div
                                    className="bg-gray-800 flex flex-col flex-shrink-0 items-center overflow-hidden p-3 rounded-md space-y-3 w-[5.5rem]"
                                    key={ id }
                                >
                                    <img
                                        alt={ `${firstName} ${lastName}'s avatar` }
                                        className="h-12 inline-block rounded-full w-12"
                                        src={ avatarUrl }
                                    />

                                    <div className="font-medium text-center text-gray-400 text-xs truncate w-16">
                                        { `${firstName} ${lastName}` }
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Tab.Panel>
);

export default DetailsTab;
