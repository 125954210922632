import { useMutation, useQueryClient } from '@tanstack/react-query';

import http from '../../utils/http';
import { initializeCsrfProtection } from '../../utils/initializeCsrfProtection';

interface SignUpData {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
}

interface RequestData {
    email: string;
    first_name: string;
    last_name: string;
    password: string;
}

const mapToRequestData = ({
    email,
    firstName,
    lastName,
    password,
}: SignUpData): RequestData => ({
    email,
    first_name: firstName,
    last_name: lastName,
    password,
});

const signUp = async (signUpData: SignUpData): Promise<void> => {
    await initializeCsrfProtection();

    await http.post<void>('/register', mapToRequestData(signUpData));
};

export const useSignUp = () => {
    const queryClient = useQueryClient();

    return (useMutation({
        mutationFn: signUp,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['currentUser'] });
        },
    }));
};
