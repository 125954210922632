import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonPage } from '@ionic/react';
import { isAxiosError } from 'axios';
import { FunctionComponent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { object, string } from 'yup';

import EmailField from '../../components/Form/EmailField';
import PasswordField from '../../components/Form/PasswordField';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import addServerErrors from '../../utils/addServerErrors';

import { useSignIn } from './useSignIn';

const schema = object({
    email: string()
        .required('Please enter your email.')
        .email('Please enter a valid email address.'),
    password: string()
        .required('Please enter your password.'),
});

interface FormData {
    email: string;
    password: string;
}

const SignInForm: FunctionComponent = () => {
    const { push } = useHistory();

    const { mutate } = useSignIn();

    const { handleSubmit, formState: { errors }, register, setError } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FormData> = (data: FormData) => mutate(data, {
        onError: error => {
            if (isAxiosError(error)) {
                addServerErrors(
                    error.response?.data.errors,
                    ['email', 'password'],
                    setError,
                );
            }
        },
        onSuccess: () => {
            push('/home');
        },
    });

    return (
        <div className="flex flex-col h-full items-center justify-center space-y-12">
            <div className="flex flex-col items-center justify-center space-y-6">
                <div className="bg-orange-500 cursor-default flex h-16 items-center justify-center rounded-xl select-none w-16">
                    <div className="font-black leading-none text-5xl text-white">
                        S
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center">
                    <div className="font-semibold leading-none text-xl text-gray-50">
                        Welcome back!
                    </div>
                </div>
            </div>

            <div className="space-y-6 w-full">
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className="gap-6 grid grid-cols-6">
                        <div className="col-span-6">
                            <EmailField
                                autoComplete="email"
                                error={ errors.email?.message }
                                id="email"
                                label="Email address"
                                required={ true }
                                { ...register('email') }
                            />
                        </div>

                        <div className="col-span-6">
                            <PasswordField
                                autoComplete="current-password"
                                error={ errors.password?.message }
                                id="password"
                                label="Password"
                                required={ true }
                                { ...register('password') }
                            />
                        </div>

                        <div className="col-span-6 pt-6">
                            <button
                                className="bg-gradient-to-br from-orange-400 to-orange-600 font-semibold inline-flex items-center justify-center px-4 py-2 rounded-md text-lg text-white w-full focus:outline-none focus:ring-2 focus:ring-orange-900 hover:[text-shadow:0_3px_12px_rgba(0,0,0,0.2)] hover:shadow-[0_1px_40px_rgba(251,146,60,0.25)]"
                                type="submit"
                            >
                                Sign In
                            </button>
                        </div>
                    </div>
                </form>

                <div className="text-center">
                    <span className="text-gray-400 text-xs">
                        Don&apos;t have an account yet?&nbsp;
                    </span>

                    <Link
                        className="border border-transparent inline-flex items-center px-1 rounded-md text-orange-500 text-xs focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-800"
                        to="/signup"
                    >
                        Try it free
                    </Link>
                </div>
            </div>
        </div>
    );
};

const SignInPage: FunctionComponent = () => {
    const { push } = useHistory();

    const { data, isLoading } = useCurrentUser();

    useEffect(() => {
        const redirectToHomePageIfAuthenticated = () => {
            if (! isLoading && data) {
                push('/home');
            }
        };

        redirectToHomePageIfAuthenticated();
    }, [data, isLoading, push]);

    return (
        <IonPage>
            <IonContent>
                <div className="bg-gray-900 h-full p-6">
                    <div className="container h-full max-w-xl mx-auto">
                        { (! isLoading && ! data) ? (
                            <SignInForm />
                        ) : null }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default SignInPage;
