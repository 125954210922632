import { PlusIcon } from '@heroicons/react/20/solid';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import ActivityCardEmptyStateIllustration from './ActivityCardEmptyStateIllustration';

const ActivityCardEmptyState: FunctionComponent = () => (
    <div className="bg-gray-800 bg-opacity-50 border border-gray-800 flex flex-col items-center justify-center px-4 py-8 rounded-lg space-y-6">
        <ActivityCardEmptyStateIllustration />

        <div className="text-center">
            <span className="text-gray-400 text-sm">
                You do not have any upcoming activities.
            </span>
            
            <br />

            <br />
            
            <span className="text-gray-400 text-sm">
                It's still not too late to start now! Add a new activity and invite your friends to join the fun!
            </span>
        </div>

        <div>
            <Link
                className="flex font-medium items-center px-4 py-2 space-x-2"
                to="/add-activity"
            >
                <PlusIcon
                    aria-hidden="true"
                    className="h-4 text-orange-500 w-4"
                />

                <div className="text-orange-500 text-sm">
                    Add new activity
                </div>
            </Link>
        </div>
    </div>
);

export default ActivityCardEmptyState;
