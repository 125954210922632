enum FormError {
    Server = 'server',
};

const addServerErrors = <T>(
    errors: { [P in keyof T]: string[] },
    fieldOrders: string[],
    setError: (
        fieldName: keyof T,
        error: {
            type: string;
            message: string;
            shouldFocus: boolean;
        },
    ) => void
): void => {
    const errorKeys = Object.keys(errors);

    errorKeys.forEach((key) => (
        setError(key as keyof T, {
            message: errors[key as keyof T].join(' '),
            shouldFocus: key === fieldOrders.filter(fieldOrder => errorKeys.includes(fieldOrder))[0],
            type: FormError.Server,
        })
    ))
};

export default addServerErrors;
