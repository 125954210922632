import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { FunctionComponent, useRef, useState } from 'react';

import SecondaryModal from '../../components/Modal/SecondaryModal';
import { Attendee } from '../../types/Activity';

interface Props {
    onClose(): void;
    onSelect(playerIds: number[]): void;
    players: Attendee[];
    selectedPlayerIds: number[];
    unavailablePlayerIds: number[];
}

const PlayerSelector: FunctionComponent<Props> = ({
    onClose,
    onSelect,
    players,
    selectedPlayerIds,
    unavailablePlayerIds,
}) => {
    const searchFieldRef = useRef<HTMLInputElement>(null);

    const [search, setSearch] = useState<string>('');
    const [selectedPlayers, setSelectedPlayers] = useState<number[]>(selectedPlayerIds);

    const getAvailablePlayers = () => (
        players.filter(({ user: { firstName, lastName } }) => (
            firstName.toLowerCase().includes(search.toLowerCase()) || lastName.toLowerCase().includes(search.toLowerCase()))
        )
    );

    return (
        <SecondaryModal
            initialFocus={ searchFieldRef }
            onClose={ onClose }
        >
            <div className="flex flex-col h-full p-6">
                <div className="flex items-center justify-between">
                    <div className="flex flex-1 items-center">
                        <button
                            className="bg-gray-700 border border-transparent font-semibold inline-flex items-center justify-center p-0.5 rounded-md focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-600"
                            onClick={ onClose }
                            type="button"
                        >
                            <XMarkIcon
                                aria-hidden="true"
                                className="h-5 text-orange-500 w-5"
                            />

                            <span className="sr-only">
                                Close
                            </span>
                        </button>
                    </div>

                    <div className="flex flex-1 items-center justify-center">
                        <h2 className="font-bold leading-none text-gray-200 text-lg">
                            Players
                        </h2>
                    </div>

                    <div className="flex flex-1 items-center justify-end">
                        <button
                            className="bg-gray-700 border border-transparent inline-flex items-center leading-none justify-center px-2 py-1 rounded-md text-orange-500 focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-600"
                            disabled={ ! selectedPlayers }
                            onClick={ () => {
                                if (selectedPlayers && selectedPlayers.length < 3) {
                                    onSelect(selectedPlayers);
                                }
                            } }
                            type="button"
                        >
                            Select
                        </button>
                    </div>
                </div>

                <div className="flex-1 pt-6">
                    { selectedPlayers.length > 2 ? (
                        <div className="pb-4 text-red-500 text-sm">
                            You could only select a maximum of 2 players
                        </div>
                    ) : null }

                    <label
                        className="font-medium group text-gray-500 text-sm focus-within:text-orange-500 hover:text-gray-100 hover:focus-within:text-orange-500"
                        htmlFor="search"
                    >
                        <div className="relative">
                            <div className="absolute flex inset-y-0 items-center pointer-events-none pl-3 left-0">
                                <MagnifyingGlassIcon
                                    aria-hidden="true" 
                                    className="h-5 w-5"
                                />

                                <div className="sr-only">
                                    Search
                                </div>
                            </div>

                            <input
                                autoFocus
                                autoComplete="off"
                                className={ `bg-gray-700 bg-opacity-50 block border-0 font-normal pl-10 rounded-md text-gray-100 w-full focus:bg-opacity-100 focus:outline-none focus:ring-0` }
                                id="search"
                                name="search"
                                onChange={ ({ target: { value } }) => setSearch(value) }
                                ref={ searchFieldRef }
                                type="text"
                                value={ search }
                            />
                        </div>
                    </label>

                    <fieldset className="min-w-0 truncate">
                        <legend className="sr-only">
                            Players
                        </legend>

                        <div>
                            { getAvailablePlayers().map(({ id, user: { avatarUrl, firstName, lastName } }) => (
                                <div
                                    className="flex flex-row-reverse group items-center py-4"
                                    key={ id }
                                >
                                    { unavailablePlayerIds.includes(id) ? (
                                        <div className="text-gray-400 text-sm group-hover:text-gray-200">
                                            Unavailable
                                        </div>
                                    ) : (
                                        <input
                                            checked={ selectedPlayers.includes(id) }
                                            className="bg-transparent border-gray-500 h-4 peer rounded w-4 text-orange-500 focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-orange-900 group-hover:border-gray-200 group-hover:checked:border-orange-500 group-hover:focus:border-orange-500"
                                            id="players"
                                            name="players"
                                            onChange={ () => {
                                                if (selectedPlayers.includes(id)) {
                                                    setSelectedPlayers(selectedPlayers.filter(playerId => playerId !== id));

                                                    return;
                                                }

                                                setSelectedPlayers([...selectedPlayers, id])
                                            } }
                                            type="checkbox"
                                        />
                                    ) }

                                    <div className="flex-1 min-w-0 pr-8 text-gray-400 group-hover:text-gray-200 group-hover:peer-checked:text-gray-200 peer-checked:font-medium peer-checked:text-gray-300">
                                        <label
                                            className="flex items-center space-x-2"
                                            htmlFor="players"
                                        >
                                            <div className="flex-shrink-0">
                                                <img
                                                    alt={ `${firstName} ${lastName}'s avatar` }
                                                    className="h-7 rounded-full w-7"
                                                    src={ avatarUrl }
                                                />
                                            </div>

                                            <div className="flex-1 min-w-0 text-sm truncate">
                                                { `${firstName} ${lastName}` }
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </fieldset>
                </div>
            </div>
        </SecondaryModal>
    );
};

export default PlayerSelector;
