
import { Tab } from '@headlessui/react';
import { ChevronLeftIcon, PlusIcon } from '@heroicons/react/20/solid';
import { IonContent, IonPage } from '@ionic/react';
import { AnimatePresence } from 'framer-motion';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import { NavBar, NavButton } from '../../components/NavBar';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Activity } from '../../types/Activity';

import AddMatch from './AddMatch';
import BottomNav from './BottomNav';
import DetailsTab from './DetailsTab';
import useActivity from './useActivity';

interface Props {
    activity: Activity
    goBack(): void;
}

const PageContent: FunctionComponent<Props> = ({
    activity,
    goBack,
}) => {
    const [isAddMatchModalOpen, setIsAddMatchModalOpen] = useState<boolean>(false);

    return (
        <>
            <Tab.Group>
                <div className="pb-20 pt-6 px-6">
                    <NavBar
                        leftItems={[
                            (
                                <NavButton
                                    onClick={ goBack }
                                    type="button"
                                >
                                    <ChevronLeftIcon
                                        aria-hidden="true"
                                        className="h-6 text-orange-500 w-6"
                                    />

                                    <span className="sr-only">
                                        Back
                                    </span>
                                </NavButton>
                            ),
                        ]}
                        rightItems={[
                            (
                                <NavButton
                                    onClick={ () => setIsAddMatchModalOpen(true) }
                                    type="button"
                                >
                                    <PlusIcon
                                        aria-hidden="true"
                                        className="h-6 text-orange-500 w-6"
                                    />

                                    <span className="sr-only">
                                        Add new activity
                                    </span>
                                </NavButton>
                            ),
                        ]}
                    />

                    <Tab.Panels as={ Fragment }>
                        <DetailsTab activity={ activity } />
                    </Tab.Panels>
                </div>
                        
                <BottomNav />
            </Tab.Group>

            <AnimatePresence>
                { isAddMatchModalOpen ? (
                    <AddMatch onClose={ () => setIsAddMatchModalOpen(false) } />
                ): null }
            </AnimatePresence>
        </>
    );
};

const ActivityPage: FunctionComponent<RouteComponentProps<{ id: string }>> = ({
    match,
}) => {
    const { goBack, push } = useHistory();

    const { data: currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
    const { data: activity, isLoading: isLoadingActivity } = useActivity(Number(match.params.id));

    useEffect(() => {
        const redirectToSignInPageIfUnuthenticated = () => {
            if (! isLoadingCurrentUser && ! currentUser) {
                push('/signin');
            }
        };

        redirectToSignInPageIfUnuthenticated();
    }, [currentUser, isLoadingCurrentUser, push]);

    return (
        <IonPage>
            <IonContent>
                <div className="bg-gray-900 h-full">
                    <div className="container h-full max-w-xl mx-auto">
                        { ((! isLoadingCurrentUser && currentUser) && (! isLoadingActivity && activity)) ? (
                            <PageContent
                                activity={ activity }
                                goBack={ goBack }
                            />
                        ) : null }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}

export default ActivityPage;
