import { useQuery } from '@tanstack/react-query';

import { Activity, ApiActivity, ApiAttendee, ApiVenue, Attendee, Venue } from '../../types/Activity';
import { ApiUser, User } from '../../types/User';
import http from '../../utils/http';

const mapToUser = ({
    email,
    first_name: firstName,
    id,
    last_name: lastName,
}: ApiUser): User => ({
    avatarUrl: `https://source.boringavatars.com/beam/120/${firstName}%20${lastName}?colors=EF4444,EAB308,22C55E,3B82F6,A855F7`,
    email,
    firstName,
    id,
    lastName,
});

const mapToAttendee = ({
    id,
    user: apiUser,
}: ApiAttendee): Attendee => ({
    id,
    user: mapToUser(apiUser),
});

const mapToVenue = ({
    address1,
    address2,
    city,
    country,
    id,
    name,
    postal_code: postalCode,
    state,
}: ApiVenue): Venue => ({
    address1,
    address2,
    city,
    country,
    id,
    name,
    postalCode,
    state,
});

const mapToActivity = ({
    attendees: apiAttendees,
    ended_at: endedAt,
    id,
    name,
    started_at: startedAt,
    venue: apiVenue,
}: ApiActivity): Activity => ({
    attendees: apiAttendees.map(apiAttendee => mapToAttendee(apiAttendee)),
    endedAt,
    id,
    name,
    startedAt,
    venue: apiVenue ? mapToVenue(apiVenue) : undefined,
});

const fetchActivity = async (id: number): Promise<ApiActivity> => {
    const { data: { data } } = await http.get<{ data: ApiActivity }>(`/api/activities/${id}`);

    return data;
};

const useActivity = (id: number) => useQuery({
    queryKey: ['activities', id],
    queryFn: () => fetchActivity(id),
    select: data => mapToActivity(data),
});

export default useActivity;
