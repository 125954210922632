import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { forwardRef, InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
    id: string;
    label: string;
    type: string;
}

const Field = forwardRef<HTMLInputElement, Props>(({
    error,
    id,
    label,
    required,
    type,
    ...props
}, ref) => {
    return (
        <label
            className="font-medium group text-gray-300 text-sm focus-within:text-orange-500 hover:text-gray-100 hover:focus-within:text-orange-500"
            htmlFor={ id }
        >
            <div className="mb-1">
                { label }
            </div>

            <div className="relative">
                <input
                    aria-describedby={ error ? `${id}-error` : '' }
                    aria-invalid={ !! error }
                    className={ `bg-transparent block border ${error ? 'border-red-800' : 'border-gray-500'} font-normal rounded-md text-gray-100 w-full focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 group-hover:border-gray-400 group-hover:focus:border-orange-500` }
                    id={ id }
                    ref={ ref }
                    type={ type }
                    { ...props }
                />

                { error ? (
                    <div className="absolute flex inset-y-0 items-center pointer-events-none pr-3 right-0">
                        <ExclamationCircleIcon
                            aria-hidden="true" 
                            className="h-5 text-red-500 w-5"
                        />
                    </div>
                ) : null }
            </div>

            { error ? (
                <p
                    className="mt-1 text-red-500"
                    id={ `${id}-error` }
                    role="alert"
                >
                    { error }
                </p>
            ) : null }
        </label>
    );
});

export default Field;
