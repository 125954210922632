import { IonContent, IonPage } from '@ionic/react';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const WelcomePage: FunctionComponent = () => {
    return (
        <IonPage>
            <IonContent>
                <div className="bg-gray-900 h-full p-6">
                    <div className="container h-full max-w-xl mx-auto">
                        <div className="flex flex-col h-full items-center justify-center space-y-20">
                            <div className="flex items-center space-x-6">
                                <div className="bg-orange-500 cursor-default flex h-16 items-center justify-center rounded-xl select-none w-16">
                                    <div className="font-black leading-none text-5xl text-white">
                                        S
                                    </div>
                                </div>

                                <h1 className="flex flex-col justify-center space-y-1">
                                    <span className="font-semibold leading-none text-3xl text-gray-50">
                                        Welcome to
                                    </span>

                                    <span className="font-black leading-none text-3xl text-orange-500">
                                        Sportaris
                                    </span>
                                </h1>
                            </div>

                            <div className="space-y-6 w-full">
                                <Link
                                    className="bg-gray-800 border border-orange-500 inline-flex items-center justify-center px-4 py-2 rounded-md text-lg text-white w-full focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
                                    to="/signin"
                                >
                                    Sign In
                                </Link>

                                <Link
                                    className="bg-gradient-to-br from-orange-400 to-orange-600 font-semibold inline-flex items-center justify-center px-4 py-2 rounded-md text-lg text-white w-full focus:outline-none focus:ring-2 focus:ring-orange-900"
                                    to="/signup"
                                >
                                    Get Started
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default WelcomePage;
