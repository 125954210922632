import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Fragment, FunctionComponent } from 'react';
import { FieldError, Merge } from 'react-hook-form';

import ResultField from './ResultField';

interface Props {
    entryOneErrors?: Merge<FieldError, (FieldError | undefined)[]>;
    entryOneScores: Array<number | string>;
    entryTwoErrors?: Merge<FieldError, (FieldError | undefined)[]>;
    entryTwoScores: Array<number | string>;
    onEntryOneScoresChange(result: Array<number | string>): void;
    onEntryTwoScoresChange(result: Array<number | string>): void;
}

const Results: FunctionComponent<Props> = ({
    entryOneErrors,
    entryOneScores,
    entryTwoErrors,
    entryTwoScores,
    onEntryOneScoresChange,
    onEntryTwoScoresChange,
}) => {
    const handleEntryOneScoresChange = (index: number, score: number | string) => {
        const scores = [...entryOneScores];
        scores[index] = score;

        onEntryOneScoresChange(scores);
    };

    const handleEntryTwoScoresChange = (index: number, score: number | string) => {
        const scores = [...entryTwoScores];
        scores[index] = score;

        onEntryTwoScoresChange(scores);
    };

    const addNewGame = () => {
        const entryOneResults = [...entryOneScores];
        const entryTwoResults = [...entryTwoScores];
        entryOneResults.push('');
        entryTwoResults.push('');

        onEntryOneScoresChange(entryOneResults);
        onEntryTwoScoresChange(entryTwoResults);
    };

    return (
        <div>
            <div className="flex items-center justify-center">
                <div className="bg-gray-800 flex-1 h-[0.2rem]" />

                <div className="font-semibold mx-3 text-xs text-gray-500">
                    Results
                </div>

                <div className="bg-gray-800 flex-1 h-[0.2rem]" />
            </div>

            <div className="gap-x-2 gap-y-6 grid grid-cols-8 pt-3">
                { entryOneScores.map((_, index) => (
                    <Fragment key={ index }>
                        <div className="col-span-3">
                            <ResultField
                                error={ Array.isArray(entryOneErrors) && entryOneErrors[index] && entryOneErrors[index].message ? entryOneErrors[index].message : undefined }
                                onChange={ score => handleEntryOneScoresChange(index, score) }
                                title="Entry one game one result"
                                value={ entryOneScores[index] }
                            />
                        </div>

                        <div className="col-span-2">
                            { index > 0 ? (
                                <button className="flex group justify-center ml-2.5 pt-2.5 rounded-full space-x-0.5 w-full">
                                    <div className="font-semibold text-xs text-gray-500 group-hover:text-gray-400">
                                        Game { index + 1 }
                                    </div>

                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-4 text-red-700 w-4 group-hover:text-red-500"
                                    />
                                </button>
                            ) : (
                                <div className="flex font-semibold justify-center pt-2.5 rounded-full text-xs text-gray-500">
                                    Game { index + 1 }
                                </div>
                            ) }
                        </div>

                        <div className="col-span-3">
                            <ResultField
                                error={ Array.isArray(entryTwoErrors) && entryTwoErrors[index] && entryTwoErrors[index].message ? entryTwoErrors[index].message : undefined }
                                onChange={ score => handleEntryTwoScoresChange(index, score) }
                                title="Entry two game one result"
                                value={ entryTwoScores[index] }
                            />
                        </div>
                    </Fragment>
                )) }
            </div>

            <div className="flex justify-center pt-6">
                <button
                    className="bg-gray-800 bg-opacity-50 border border-transparent inline-flex items-center justify-center px-2 py-1.5 rounded-md focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
                    onClick={ addNewGame }
                    type="button"
                >
                    <div className="flex items-center pr-1 space-x-1.5">
                        <PlusIcon
                            aria-hidden="true"
                            className="h-4 text-orange-700 w-4"
                        />

                        <span className="text-orange-500 text-xs">
                            Add game
                        </span>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Results;
