import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, addHours, format, formatISO, isBefore, startOfHour, startOfToday } from 'date-fns';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { date, object, string } from 'yup';

import Modal from '../../components/Modal';

import BadmintonIcon from './BadmintonIcon';
import useCreateActivity from './useCreateActivity';

interface Props {
    onClose(): void;
}

interface FormData {
    endTime: string;
    name: string;
    startDate: string;
    startTime: string;
    venue?: string;
}

const schema = object({
    endTime: string()
        .required('Please enter an end time for this activity.'),
    name: string()
        .required('Please enter a title for this activity.'),
    startDate: date()
        .min(startOfToday(), 'Please enter a date not later than today.'),
    startTime: string()
        .required('Please enter a start time for this activity.'),
    venue: string()
        .nullable(),
});

const AddActivity: FunctionComponent<Props> = ({
    onClose,
}) => {
    const { push } = useHistory();
    const { mutate } = useCreateActivity();

    const currentDateTime = new Date();

    const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
        defaultValues: {
            endTime: format(addHours(startOfHour(currentDateTime), 2), 'HH:mm'),
            startDate: format(addHours(startOfHour(currentDateTime), 1), 'yyyy-MM-dd'),
            startTime: format(addHours(startOfHour(currentDateTime), 1), 'HH:mm'),
        },
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FormData> = ({
        endTime,
        name,
        startDate,
        startTime,
        venue,
    }) => {
        const [startTimeHours, startTimeMinutes] = startTime.split(':');
        const startedAtDateTime = new Date(startDate.toString()).setHours(Number(startTimeHours), Number(startTimeMinutes));

        const [endTimeHours, endTimeMinutes] = endTime.split(':');
        const endedAtDateTime = new Date(startDate.toString()).setHours(Number(endTimeHours), Number(endTimeMinutes));
        
        if (isBefore(startedAtDateTime, endedAtDateTime)) {
            addDays(endedAtDateTime, 1);
        }

        mutate({
            endedAt: formatISO(endedAtDateTime),
            name,
            startedAt: formatISO(startedAtDateTime),
            // venue,
        }, {
            onSuccess: ({ id }) => {
                push(`/activities/${id}`);

                onClose();
            }
        });
    };

    return (
        <Modal onClose={ onClose }>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <div className="flex flex-col h-full p-6">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-1 items-center">
                            <button
                                className="bg-gray-800 border border-transparent font-semibold inline-flex items-center justify-center p-0.5 rounded-md focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
                                onClick={ onClose }
                                type="button"
                            >
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-5 text-orange-500 w-5"
                                />

                                <span className="sr-only">
                                    Close
                                </span>
                            </button>
                        </div>

                        <div className="flex flex-1 items-center justify-center">
                            <h2 className="font-bold leading-none text-gray-200 text-lg">
                                New activity
                            </h2>
                        </div>

                        <div className="flex flex-1 items-center justify-end">
                            <button
                                className="bg-gray-800 border border-transparent inline-flex items-center leading-none justify-center px-2 py-1 rounded-md text-orange-500 focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-gray-700"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>

                    </div>

                    <div className="flex-1 pt-6">
                        <div>
                            <div className="flex space-x-3">
                                <div className="mt-1">
                                    <div className="bg-gray-800 bg-opacity-50 border border-transparent p-2 rounded-md">
                                        <BadmintonIcon />
                                    </div>
                                </div>

                                <div className="flex-1">
                                    <label
                                        className="sr-only"
                                        htmlFor="name"
                                    >
                                        Title
                                    </label>

                                    <div className="relative">
                                        <input
                                            aria-describedby={ errors.name?.message ? 'name-error' : '' }
                                            aria-invalid={ !! errors.name?.message }
                                            autoFocus
                                            className="bg-transparent block border-transparent font-semibold rounded-md text-gray-100 text-lg w-full focus:border-transparent focus:ring-transparent"
                                            id="name"
                                            placeholder="Add title"
                                            type="text"
                                            { ...register('name') }
                                        />

                                        { errors.name?.message ? (
                                            <div className="absolute flex inset-y-0 items-center pointer-events-none pr-3 right-0">
                                                <ExclamationCircleIcon
                                                    aria-hidden="true" 
                                                    className="h-5 text-red-500 w-5"
                                                />
                                            </div>
                                        ) : null }
                                    </div>
                                </div>
                            </div>

                            { errors.name?.message ? (
                                <p
                                    className="mt-1 text-red-500"
                                    id="name-error"
                                    role="alert"
                                >
                                    { errors.name?.message }
                                </p>
                            ) : null }
                        </div>

                        <div>
                            <div>
                                <div className="font-medium pt-4 text-gray-600 text-xs tracking-wide uppercase">
                                    Date & Time
                                </div>

                                <div className="gap-4 grid grid-cols-6 pt-4">
                                    <div className="col-span-6">
                                        <label
                                            className="font-medium group text-gray-500 text-sm focus-within:text-orange-500 hover:text-gray-100 hover:focus-within:text-orange-500"
                                            htmlFor="start-date"
                                        >
                                            <div className="relative">
                                                <div className="absolute flex inset-y-0 items-center left-0 pl-3 pointer-events-none">
                                                    on
                                                </div>

                                                <input
                                                    aria-describedby={ errors.startDate?.message ? 'start-date-error' : '' }
                                                    aria-invalid={ !! errors.startDate?.message }
                                                    className={ `bg-gray-800 bg-opacity-50 block border-0 font-normal pl-10 rounded-md text-gray-100 w-full focus:bg-opacity-100 focus:outline-none focus:ring-0` }
                                                    id="start-date"
                                                    type="date"
                                                    { ...register('startDate') }
                                                />

                                                { errors.startDate?.message ? (
                                                    <div className="absolute flex inset-y-0 items-center pointer-events-none pr-3 right-0">
                                                        <ExclamationCircleIcon
                                                            aria-hidden="true" 
                                                            className="h-5 text-red-500 w-5"
                                                        />
                                                    </div>
                                                ) : null }
                                            </div>

                                            { errors.startDate?.message ? (
                                                <p
                                                    className="mt-1 text-red-500"
                                                    id="start-date-error"
                                                    role="alert"
                                                >
                                                    { errors.startDate?.message }
                                                </p>
                                            ) : null }
                                        </label>
                                    </div>

                                    <div className="col-span-3">
                                        <label
                                            className="font-medium group text-gray-500 text-sm focus-within:text-orange-500 hover:text-gray-100 hover:focus-within:text-orange-500"
                                            htmlFor="start-time"
                                        >
                                            <div className="relative">
                                                <div className="absolute flex inset-y-0 items-center left-0 pl-3 pointer-events-none">
                                                    from
                                                </div>

                                                <input
                                                    aria-describedby={ errors.startTime?.message ? 'start-time-error' : '' }
                                                    aria-invalid={ !! errors.startTime?.message }
                                                    className={ `bg-gray-800 bg-opacity-50 block border-0 font-normal pl-14 rounded-md text-gray-100 w-full focus:bg-opacity-100 focus:outline-none focus:ring-0` }
                                                    id="start-time"
                                                    type="time"
                                                    { ...register('startTime') }
                                                />

                                                { errors.startTime?.message ? (
                                                    <div className="absolute flex inset-y-0 items-center pointer-events-none pr-3 right-0">
                                                        <ExclamationCircleIcon
                                                            aria-hidden="true" 
                                                            className="h-5 text-red-500 w-5"
                                                        />
                                                    </div>
                                                ) : null }
                                            </div>

                                            { errors.startTime?.message ? (
                                                <p
                                                    className="mt-1 text-red-500"
                                                    id="start-time-error"
                                                    role="alert"
                                                >
                                                    { errors.startTime?.message }
                                                </p>
                                            ) : null }
                                        </label>
                                    </div>

                                    <div className="col-span-3">
                                        <label
                                            className="font-medium group text-gray-500 text-sm focus-within:text-orange-500 hover:text-gray-100 hover:focus-within:text-orange-500"
                                            htmlFor="end-time"
                                        >
                                            <div className="relative">
                                                <div className="absolute flex inset-y-0 items-center left-0 pl-3 pointer-events-none">
                                                    to
                                                </div>

                                                <input
                                                    aria-describedby={ errors.endTime?.message ? 'end-time-error' : '' }
                                                    aria-invalid={ !! errors.endTime?.message }
                                                    className={ `bg-gray-800 bg-opacity-50 block border-0 font-normal pl-10 rounded-md text-gray-100 w-full focus:bg-opacity-100 focus:outline-none focus:ring-0` }
                                                    id="end-time"
                                                    type="time"
                                                    { ...register('endTime') }
                                                />

                                                { errors.endTime?.message ? (
                                                    <div className="absolute flex inset-y-0 items-center pointer-events-none pr-3 right-0">
                                                        <ExclamationCircleIcon
                                                            aria-hidden="true" 
                                                            className="h-5 text-red-500 w-5"
                                                        />
                                                    </div>
                                                ) : null }
                                            </div>

                                            { errors.endTime?.message ? (
                                                <p
                                                    className="mt-1 text-red-500"
                                                    id="end-time-error"
                                                    role="alert"
                                                >
                                                    { errors.endTime?.message }
                                                </p>
                                            ) : null }
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="font-medium pt-4 text-gray-600 text-xs tracking-wide uppercase">
                                    Location
                                </div>

                                <div className="gap-4 grid grid-cols-6 pt-4">
                                    <div className="col-span-6">
                                        <label
                                            className="font-medium group text-gray-500 text-sm focus-within:text-orange-500 hover:text-gray-100 hover:focus-within:text-orange-500"
                                            htmlFor="venue"
                                        >
                                            <div className="relative">
                                                <div className="absolute flex inset-y-0 items-center left-0 pl-3 pointer-events-none">
                                                    at
                                                </div>

                                                <input
                                                    aria-describedby={ errors.venue?.message ? 'venue-error' : '' }
                                                    aria-invalid={ !! errors.venue?.message }
                                                    className={ `bg-gray-800 bg-opacity-50 block border-0 font-normal pl-10 rounded-md text-gray-100 w-full focus:bg-opacity-100 focus:outline-none focus:ring-0` }
                                                    id="venue"
                                                    type="text"
                                                    { ...register('venue') }
                                                />

                                                { errors.venue?.message ? (
                                                    <div className="absolute flex inset-y-0 items-center pointer-events-none pr-3 right-0">
                                                        <ExclamationCircleIcon
                                                            aria-hidden="true" 
                                                            className="h-5 text-red-500 w-5"
                                                        />
                                                    </div>
                                                ) : null }
                                            </div>

                                            { errors.venue?.message ? (
                                                <p
                                                    className="mt-1 text-red-500"
                                                    id="venue-error"
                                                    role="alert"
                                                >
                                                    { errors.venue?.message }
                                                </p>
                                            ) : null }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default AddActivity;
