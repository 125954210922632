import { ClockIcon, MapPinIcon } from '@heroicons/react/24/solid';
import { FunctionComponent } from 'react';

import BadmintonIcon from './BadmintonIcon';

const ActivityCardLoadingState: FunctionComponent = () => (
    <div className="bg-gray-800 bg-opacity-50 border border-gray-800 p-4 rounded-lg">
        <div className="flex items-center space-x-3">
            <div className="bg-gray-800 bg-opacity-30 border border-transparent p-2 rounded-md">
                <BadmintonIcon />
            </div>

            <div className="animate-pulse bg-gray-700 bg-opacity-50 h-3.5 w-40" />
        </div>

        <div className="pt-5 space-y-4">
            <div className="flex space-x-2">
                <div className="flex-shrink-0">
                    <ClockIcon
                        aria-hidden="true"
                        className="h-4 text-gray-700 w-4"
                    />
                </div>

                <div className="space-y-1">
                    <div className="flex items-center py-0.5">
                        <div className="animate-pulse bg-gray-700 bg-opacity-50 h-3 w-28" />
                    </div>

                    <div className="flex items-center py-0.5">
                        <div className="animate-pulse bg-gray-700 bg-opacity-50 h-3 w-32" />
                    </div>
                </div>
            </div>

            <div className="flex space-x-2">
                <div className="flex-shrink-0">
                    <MapPinIcon
                        aria-hidden="true"
                        className="h-4 text-gray-700 w-4"
                    />
                </div>

                <div className="overflow-x-hidden space-y-1">
                    <div className="flex items-center py-0.5">
                        <div className="animate-pulse bg-gray-700 bg-opacity-50 h-3 w-60" />
                    </div>

                    <div className="flex items-center py-0.5">
                        <div className="animate-pulse bg-gray-700 bg-opacity-50 h-3 w-72" />
                    </div>
                </div>
            </div>

            <div className="flex justify-between pt-2">
                <div className="flex items-center space-x-2">
                    <div className="flex -space-x-1 overflow-hidden p-0.5">
                        <div className="animate-pulse bg-gray-700 bg-opacity-50 inline-block h-6 ring-2 ring-gray-600 ring-opacity-50 rounded-full w-6" />

                        <div className="animate-pulse bg-gray-700 bg-opacity-50 inline-block h-6 ring-2 ring-gray-600 ring-opacity-50 rounded-full w-6" />

                        <div className="animate-pulse bg-gray-700 bg-opacity-50 inline-block h-6 ring-2 ring-gray-600 ring-opacity-50 rounded-full w-6" />

                        <div className="animate-pulse bg-gray-700 bg-opacity-50 inline-block h-6 ring-2 ring-gray-600 ring-opacity-50 rounded-full w-6" />
                    </div>

                    <div className="flex items-center py-0.5">
                        <div className="animate-pulse bg-gray-700 bg-opacity-50 h-3 w-16" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default ActivityCardLoadingState;
