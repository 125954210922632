import { Dialog } from '@headlessui/react';
import { motion } from 'framer-motion';
import { FunctionComponent, MutableRefObject, ReactElement } from 'react';

interface Props {
    children: ReactElement;
    initialFocus?: MutableRefObject<HTMLElement | null>;
    onClose(): void;
}

const TRANSITION = {
    DURATION: 0.4,
    EASE: [0.32, 0.72, 0, 1],
}

const SecondaryModal: FunctionComponent<Props> = ({
    children,
    initialFocus,
    onClose,
}) => {
    return (
        <Dialog
            className="fixed inset-0 z-10"
            initialFocus={ initialFocus }
            onClose={ onClose }
            open={ true }
            static={ true }
        >
            <Dialog.Overlay
                animate="open"
                as={ motion.div }
                exit="close"
                className="bg-black/0 fixed inset-0"
                initial="close"
                variants={ {
                    close: {
                        opacity: 0,
                        transition: {
                            duration: TRANSITION.DURATION,
                            ease: TRANSITION.EASE,
                        },
                    },
                    open: {
                        opacity: 1,
                        transition: {
                            duration: TRANSITION.DURATION,
                            ease: TRANSITION.EASE,
                        },
                    },
                } }
            />

            <div className="container h-full max-w-xl mx-auto pointer-events-none">
                <motion.div
                    animate={ {
                        transition: {
                            duration: TRANSITION.DURATION,
                            ease: TRANSITION.EASE,
                        },
                        y: 0,
                    } }
                    className="bg-gray-800 h-full mt-8 pointer-events-auto rounded-t-3xl shadow-xl"
                    exit={ {
                        transition: {
                            duration: TRANSITION.DURATION,
                            ease: TRANSITION.EASE,
                        },
                        y: '100%',
                    } }
                    initial={ { y: '100%' } }
                >
                    { children }
                </motion.div>
            </div>
        </Dialog>
    );
};

export default SecondaryModal;
