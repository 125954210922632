import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route } from 'react-router-dom';

import ActivityPage from './pages/Activity';
import HomePage from './pages/Home';
import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
import WelcomePage from './pages/Welcome';

import './tailwind.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

import './overrides.css';

setupIonicReact();

const queryClient = new QueryClient();

const App: React.FC = () => (
    <QueryClientProvider client={ queryClient }>
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route
                        component={ HomePage }
                        exact
                        path="/home"
                    />

                    <Route
                        component={ ActivityPage }
                        exact
                        path="/activities/:id"
                    />

                    <Route
                        component={ SignInPage }
                        exact
                        path="/signin"
                    />

                    <Route
                        component={ SignUpPage }
                        exact
                        path="/signup"
                    />

                    <Route
                        component={ WelcomePage }
                        exact
                        path="/"
                    />
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    </QueryClientProvider>
);

export default App;
