
import { Tab } from '@headlessui/react';
import { NewspaperIcon, TableCellsIcon, TrophyIcon } from '@heroicons/react/20/solid';
import { FunctionComponent } from 'react';

const BottomNav: FunctionComponent = () => (
    <nav className="bg-gray-800 bottom-0 fixed justify-between left-0 p-[0.1875rem] right-0 rounded-t-lg">
        <div className="container max-w-xl mx-auto">
            <Tab.List className="flex space-x-3">
                <Tab className="flex flex-1 flex-col group items-center py-1.5 rounded-md space-y-0.5 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-orange-500 focus:ring-orange-900">
                    { ({ selected }) => (
                        <>
                            <div>
                                <NewspaperIcon
                                    aria-hidden="true"
                                    className={ `h-4 ${selected ? 'text-orange-500' : 'text-gray-700'} w-4 ${selected ? '' : 'group-hover:text-gray-600'}` }
                                />
                            </div>

                            <div className={ `font-medium ${selected ? 'text-orange-500' : 'text-gray-600'} text-[10px] ${selected ? '' : 'group-hover:text-gray-500'}` }>
                                Details
                            </div>
                        </>
                    ) }
                </Tab>

                <Tab className="flex flex-1 flex-col group items-center py-1.5 rounded-md space-y-0.5 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-orange-500 focus:ring-orange-900">
                    { ({ selected }) => (
                        <>
                            <div>
                                <TableCellsIcon
                                    aria-hidden="true"
                                    className={ `h-4 ${selected ? 'text-orange-500' : 'text-gray-700'} w-4 ${selected ? '' : 'group-hover:text-gray-600'}` }
                                />
                            </div>

                            <div className={ `font-medium ${selected ? 'text-orange-500' : 'text-gray-600'} text-[10px] ${selected ? '' : 'group-hover:text-gray-500'}` }>
                                Matches
                            </div>
                        </>
                    ) }
                </Tab>

                <Tab className="flex flex-1 flex-col group items-center py-1.5 rounded-md space-y-0.5 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-offset-orange-500 focus:ring-orange-900">
                    { ({ selected }) => (
                        <>
                            <div>
                                <TrophyIcon
                                    aria-hidden="true"
                                    className={ `h-4 ${selected ? 'text-orange-500' : 'text-gray-700'} w-4 ${selected ? '' : 'group-hover:text-gray-600'}` }
                                />
                            </div>

                            <div className={ `font-medium ${selected ? 'text-orange-500' : 'text-gray-600'} text-[10px] ${selected ? '' : 'group-hover:text-gray-500'}` }>
                                Leaderboard
                            </div>
                        </>
                    ) }
                </Tab>
            </Tab.List>
        </div>
    </nav>
);

export default BottomNav;
