import { ChevronRightIcon, ClockIcon, MapPinIcon } from '@heroicons/react/24/solid';
import { format, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Activity } from '../../types/Activity';

import BadmintonIcon from './BadmintonIcon';

interface Props {
    activity: Activity;
    currentUserId: number;
}

const ActivityCard: FunctionComponent<Props> = ({
    activity,
    currentUserId,
}) => {
    const currentUserAttendee = activity.attendees.find(({ user: { id } }) => id === currentUserId);

    return (
        <Link
            className="bg-gray-800 bg-opacity-50 block border border-gray-800 cursor-pointer group p-4 rounded-lg focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-900 hover:bg-opacity-75"
            to={ `/activities/${activity.id}` }
        >
            <div className="flex items-center space-x-3">
                <div className="bg-gray-800 bg-opacity-30 border border-transparent p-2 rounded-md group-hover:bg-opacity-0">
                    <BadmintonIcon />
                </div>

                <div className="font-semibold text-gray-200 text-sm truncate group-hover:text-gray-50">
                    { activity.name }
                </div>
            </div>

            <div className="pt-5 space-y-4">
                <div className="flex space-x-2">
                    <div className="flex-shrink-0">
                        <ClockIcon
                            aria-hidden="true"
                            className="h-4 text-gray-700 w-4 group-hover:text-gray-600"
                        />
                    </div>

                    <div className="space-y-1">
                        <div className="flex items-center">
                            <span className="font-medium text-gray-400 text-xs group-hover:text-gray-300">
                                { format(parseISO(activity.startedAt), 'd LLL yyyy (EEEE)') }
                            </span>
                        </div>

                        <div className="flex items-center">
                            <span className="font-medium text-gray-500 text-xs group-hover:text-gray-400">
                                { format(parseISO(activity.startedAt), 'H:mm') }
                            </span>
                            
                            <div className="mx-1">
                                <ChevronRightIcon
                                    aria-hidden="true"
                                    className="h-3 text-gray-500 w-3 group-hover:text-gray-400"
                                />
                            </div>
                            
                            <span className="font-medium text-gray-500 text-xs group-hover:text-gray-400">
                                { format(parseISO(activity.endedAt), 'H:mm') } ({ formatDuration(intervalToDuration({ start: parseISO(activity.startedAt), end: parseISO(activity.endedAt) })) }) 
                            </span>
                        </div>
                    </div>
                </div>

                { activity.venue ? (
                    <div className="flex space-x-2">
                        <div className="flex-shrink-0">
                            <MapPinIcon
                                aria-hidden="true"
                                className="h-4 text-gray-700 w-4 group-hover:text-gray-600"
                            />
                        </div>

                        <div className="overflow-x-hidden space-y-1">
                            <div className="flex items-center">
                                <span className="font-medium text-gray-400 text-xs truncate group-hover:text-gray-300">
                                    {/* { activity.venue.name } */}
                                </span>
                            </div>

                            <div className="flex items-center">
                                <span className="font-medium text-gray-500 text-xs truncate group-hover:text-gray-400">
                                    {/* { `${address}, ${postalCode} ${city}, ${state}` } */}
                                </span>
                            </div>
                        </div>
                    </div>
                ) : null }

                <div className="flex justify-between pt-2">
                    <div className="flex items-center space-x-2">
                        <div className="flex -space-x-1 overflow-hidden p-0.5">
                            { currentUserAttendee ? (
                                <img
                                    alt={ `${currentUserAttendee.user.firstName} ${currentUserAttendee.user.lastName}'s avatar` }
                                    className="inline-block h-6 ring-2 ring-gray-700 rounded-full w-6 group-hover:ring-gray-600"
                                    src={ currentUserAttendee.user.avatarUrl }
                                />
                            ) : null }

                            { activity.attendees.filter(({ user: { id } }) => id !== currentUserId).map(({ id, user: { avatarUrl, firstName, lastName } }) => (
                                <img
                                    alt={ `${firstName} ${lastName}'s avatar` }
                                    className="inline-block h-6 ring-2 ring-gray-700 rounded-full w-6 group-hover:ring-gray-600"
                                    key={ id }
                                    src={ avatarUrl }
                                />
                            )) }
                        </div>

                        <div className="leading-none text-xs text-gray-400 group-hover:text-gray-300">
                            { activity.attendees.length } going
                        </div>
                    </div>

                    <div className="flex items-end">
                        { currentUserAttendee ? (
                            <span className="border border-green-800 font-semibold px-4 py-0.5 rounded text-xs text-green-600 group-hover:border-green-700 group-hover:text-green-500">
                                Going
                            </span>
                        ) : null }
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ActivityCard;
