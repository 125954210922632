import { forwardRef, InputHTMLAttributes } from 'react';

import Field from './Field';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
    id: string;
    label: string;
}

const TextField = forwardRef<HTMLInputElement, Props>(({
    error,
    id,
    label,
    ...props
}, ref) => (
    <Field
        error={ error }
        id={ id }
        label={ label }
        ref={ ref }
        type="text"
        { ...props }
    />
));

export default TextField;
