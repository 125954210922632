import Axios from 'axios';

const http = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
});

export default http;

export enum HttpStatusCode {
    Unauthorized = 401,
};
