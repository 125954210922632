import { PlusIcon } from '@heroicons/react/20/solid';
import { IonContent, IonPage } from '@ionic/react';
import { AnimatePresence } from 'framer-motion';
import { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { NavBar, NavButton } from '../../components/NavBar';

import { useCurrentUser } from '../../hooks/useCurrentUser';
import { User } from '../../types/User';

import ActivityCards from './ActivityCards';
import AddActivity from './AddActivity';

interface Props {
    currentUser: User;
}

const PageContent: FunctionComponent<Props> = ({
    currentUser,
}) => {
    const [isAddActivityModalOpen, setIsAddActivityModalOpen] = useState<boolean>(false);

    return (
        <>
            <div className="flex flex-col h-full">
                <NavBar
                    centerItems={[
                        (
                            <h1 className="flex-1 font-bold leading-none text-gray-300 text-2xl">
                                Activities
                            </h1>
                        ),
                    ]}
                    leftItems={[
                        (
                            <div className="bg-transparent p-0.5 rounded-full">
                                <img
                                    alt={ `${currentUser.firstName} ${currentUser.lastName}'s avatar` }
                                    className="h-7 inline-block ring-2 ring-gray-700 rounded-full w-7"
                                    src={ currentUser.avatarUrl }
                                />
                            </div>
                        ),
                    ]}
                    rightItems={[
                        (
                            <NavButton
                                onClick={ () => setIsAddActivityModalOpen(true) }
                                type="button"
                            >
                                <PlusIcon
                                    aria-hidden="true"
                                    className="h-6 text-orange-500 w-6"
                                />

                                <span className="sr-only">
                                    Add new activity
                                </span>
                            </NavButton>
                        )
                    ]}
                />

                <div className="pt-6">
                    <ActivityCards currentUserId={ currentUser.id } />
                </div>
            </div>

            <AnimatePresence>
                { isAddActivityModalOpen ? (
                    <AddActivity onClose={ () => setIsAddActivityModalOpen(false) } />
                ): null }
            </AnimatePresence>
        </>
    );
};

const HomePage: FunctionComponent = () => {
    const { push } = useHistory();

    const { data, isLoading } = useCurrentUser();

    useEffect(() => {
        const redirectToSignInPageIfUnuthenticated = () => {
            if (! isLoading && ! data) {
                push('/signin');
            }
        };

        redirectToSignInPageIfUnuthenticated();
    }, [data, isLoading, push]);

    return (
        <IonPage>
            <IonContent>
                <div className="bg-gray-900 h-full p-6">
                    <div className="container h-full max-w-xl mx-auto">
                        { (! isLoading && data) ? (
                            <PageContent currentUser={ data } />
                        ) : null }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default HomePage;
