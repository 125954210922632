import { Fragment, FunctionComponent, ReactNode } from 'react';

interface Props {
    centerItems?: ReactNode[];
    leftItems?: ReactNode[];
    rightItems?:ReactNode[];
}

export const NavBar: FunctionComponent<Props> = ({
    centerItems,
    leftItems,
    rightItems,
}) => (
    <nav className="flex items-center justify-between">
        <div className="flex flex-1 items-center">
            { leftItems?.map((item, index) => (
                <Fragment key={ index }>
                    { item }
                </Fragment>
            )) }
        </div>

        <div className="flex flex-1 items-center justify-center">
            { centerItems?.map((item, index) => (
                <Fragment key={ index }>
                    { item }
                </Fragment>
            )) }
        </div>


        <div className="flex flex-1 items-center justify-end">
            { rightItems?.map((item, index) => (
                <Fragment key={ index }>
                    { item }
                </Fragment>
            )) }
        </div>
    </nav>
);
