import { FunctionComponent } from 'react';

import ActivityCard from './ActivityCard';
import ActivityCardLoadingState from './ActivityCardLoadingState';
import useCurrentUserActivities from './useCurrentUserActivities';
import ActivityCardEmptyState from './ActivityCardEmptyState';
import ActivityCardErrorState from './ActivityCardErrorState';

interface Props {
    currentUserId: number;
}

const ActivityCards: FunctionComponent<Props> = ({
    currentUserId,
}) => {
    const { data, isError, isSuccess } = useCurrentUserActivities();

    if (isSuccess && data) {
        if (data.length === 0) {
            return (
                <ActivityCardEmptyState />
            );
        }

        return (
            <div className="space-y-5">
                { data.map(activity => (
                    <ActivityCard
                        activity={ activity }
                        currentUserId={ currentUserId }
                        key={ activity.id }
                    />
                )) }
            </div>
        );
    }

    if (isError) {
        return (
            <ActivityCardErrorState />
        );
    }

    return (
        <div className="space-y-5">
            <ActivityCardLoadingState />

            <ActivityCardLoadingState />

            <ActivityCardLoadingState />
        </div>
    );
};

export default ActivityCards;
